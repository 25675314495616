import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import {useRef, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {cnb} from 'cnbuilder';
import {v4 as uuidv4} from 'uuid';
import add from 'date-fns/add';
import isNil from 'lodash/isNil';
import addHours from 'date-fns/addHours';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import parse from 'date-fns/parse';
import {format} from 'date-fns-tz';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Button from '@teladoc/pulse/ui/Button';
import DatePicker from '@teladoc/pulse/ui/DatePicker';
import Form from '@teladoc/pulse/ui/Form';
import IconInfoDefault from '@teladoc/pulse/icons/info-default.svg';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import Select from '@teladoc/pulse/ui/Select';
import Chip from '@teladoc/pulse/ui/Chip';
import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import TextArea from '@teladoc/pulse/ui/TextArea';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import AcuityError from '../ErrorMessages/AcuityError';
import CommonUtils from '../utils/common-utils';
import UserInfo from '../UserInfo/UserInfo';
import SharedCoaching from '../utils/SharedCoaching';
import UserInfoAPI from '../UserInfo/userInfo-api';
import TimeTap from '../utils/TimeTap';
import {
    timeZones,
    defaultTimeZone,
    localizedTime,
    getClientTimeZoneObject,
} from '../utils/Timezone';
import {
    PRODUCTION_SERVICES_IDS,
    TESTING_SERVICES_IDS,
    ANY_COACH,
    IS_PROD,
    FEATURE_FLAG_NAMES,
} from '../config';
import MixpanelUtils from '../common/mix-panel';
import Loader from '../common/loader/Loader';
import css2 from './SchedulingFormV2.scss';

const CONFIRMATION = [
    {
        key: 'No',
        value: true,
    },
    {
        key: 'Yes',
        value: false,
    },
];
const DATE_FORMAT = 'yyyy-MM-dd';
const SESSION_DURATION = '30';
const languages = [
    {
        key: 'english',
        value: 'en-US',
    },
    {
        key: 'spanish',
        value: 'es-US',
    },
];
const coaches = [
    {
        key: '',
        value: '',
    },
    {
        key: ANY_COACH,
        value: 'Different coach',
    },
];
const AWM_PROGRAM = 'ADV_WEIGHT_MANAGEMENT';
const ADPP_PROGRAM = 'ADV_PREDIABETES';
const CWC_PROGRAM = 'COMPR_WEIGHT_CARE';
const CDPC_PROGRAM = 'COMPR_PREDIABETES_CARE';
const RANKED_PROGRAMS = [
    'HEART_FAILURE',
    'DIABETES',
    'DIABETES_DEVICEFLEX',
    'PREDIABETES',
    'HYPERTENSION',
    'CHRONIC_KIDNEY_DISEASE',
    'WEIGHT_MANAGEMENT',
    AWM_PROGRAM,
    CWC_PROGRAM,
];

const SchedulingFormV2 = () => {
    const {t} = useTransLoader('schedulingForm');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const contentRef = useRef();
    const {selectedCountry, selectedLang, changeLanguage} = useI18nContext();
    // const {selectedCountry, selectedLang} = useI18nContext();
    const {sharedCoaching} = useSelector(state => state.scheduler);
    const {
        appointmentTypesInfo,
        firstName,
        lastName,
        email,
        phones,
    } = useSelector(state => state.userData.userData);
    const [hasSessionInPastYear, setHasSessionInPastYear] = useState(null);
    const {token, uuid} = useSelector(state => state.userData.auth);
    const [isSharedCoaching, setIsSharedCoaching] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(
        defaultTimeZone(selectedCountry, selectedLang)
    );
    const [APIError, setAPIError] = useState();
    const [sessionToken, setSessionToken] = useState(token);
    const [rankedProgram, setRankedProgram] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [hasPastSession, setHasPastSession] = useState(false);
    const [selectedAnyCoach, setSelectedAnyCoach] = useState(false);
    const [availableDatesData, setAvailableDatesData] = useState(null);
    const [availableDates, setAvailableDates] = useState([]);
    const [initialMonth, setInitialMonth] = useState();
    const [filteredTimeSlots, setFilteredTimeSlots] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [timeSlotsData, setTimeSlotsData] = useState(null);
    const [selectedTimeSlotData, setSelectedTimeSlotData] = useState(null);
    const [clientObject, setClientObject] = useState(null);
    const [locationObject, setLocationObject] = useState(null);
    const [reasonObject, setReasonObject] = useState(null);
    const [commentFieldObject, setCommentFieldObject] = useState(null);
    const [coachesData, setCoachesData] = useState(null);
    const [listOfCoachNames, setListOfCoachNames] = useState([]);
    const [selectedCoachId, setSelectedCoachId] = useState(null);
    const [coachId, setCoachId] = useState(null);
    const [notes, setNotes] = useState('');
    const [timeKey, setTimeKey] = useState(uuidv4());
    const [sessionKey, setSessionKey] = useState('0');
    const [uuidFieldObject, setUuidFieldObject] = useState(null);
    const [internalUserId, setInternalUserId] = useState(null);
    const [showFirstVisitMsg, setShowFirstVisitMsg] = useState(false);
    const [showPastVisitMsg, setShowPastVisitMsg] = useState(false);
    const [previousSessionCoach, setPreviousSessionCoach] = useState(null);
    const showPastSession = false;
    const oneApp =
        Arg('oneapp') ||
        Arg('ccmOneApp') ||
        window.location.hostname.includes('teladoc');
    const serviceTypesIds = IS_PROD
        ? PRODUCTION_SERVICES_IDS
        : TESTING_SERVICES_IDS;
    const TIMETAP_CCM_LOCATION_ID = process.env.TIMETAP_CCM_LOCATION_ID;
    const [enableNewUi, setEnableNewUi] = useState(false); // TODO: Remove this feature flag
    const {featureFlags} = useFeatureFlagContext();
    const useNewUi = Arg('enableNewUi') === true;
    const isMobile = Arg('mobile') === true;
    const [isPreviousCoachSelected, setIsPreviousCoachSelected] = useState(
        true
    );
    const timezoneChange = timezone => {
        const timeZoneValues = getClientTimeZoneObject(
            selectedCountry,
            timezone.value
        );

        setAvailableTimes(
            availableTimes.map(timeSlot => {
                const formattedTime = new Date(
                    Number(timeSlot.value)
                ).toISOString();

                return {
                    label: localizedTime(
                        formattedTime,
                        timezone.value,
                        selectedLang
                    ),
                    value: timeSlot.value,
                };
            })
        );
        setSelectedTimezone(timezone);

        clientObject.timeZone.timeZoneCode = timeZoneValues?.timeZoneCode;
        clientObject.timeZone.timeZoneDesc = timeZoneValues?.timeZoneDesc;
        clientObject.timeZone.timeZoneId = timeZoneValues?.timeZoneId;
    };

    const setServiceTypeId = useCallback(() => {
        const programs = [];

        if (appointmentTypesInfo) {
            appointmentTypesInfo.forEach(type => {
                switch (type.program) {
                    case AWM_PROGRAM:
                    case ADPP_PROGRAM:
                        programs.push(AWM_PROGRAM);
                        break;
                    case CWC_PROGRAM:
                    case CDPC_PROGRAM:
                        programs.push(CWC_PROGRAM);
                        break;
                    default:
                        programs.push(type.program);
                        break;
                }
            });
        }

        // https://teladocpa.sharepoint.com/:x:/s/CoachingTeamLibrary/Eb3t_Q5mDTdImG_2x3SzICIBtnU6l_ISELYoEquncE5r8w?e=m7iekB
        // i.e. If a member is enrolled in both DM and WM, they will be assigned a DM coach
        const topProgram = RANKED_PROGRAMS.find(program =>
            programs.includes(program)
        );

        setHasPastSession(hasSessionInPastYear);
        setShowFirstVisitMsg(hasSessionInPastYear === false);
        setShowPastVisitMsg(hasSessionInPastYear === true);
        setRankedProgram(topProgram);
        setServiceId(
            serviceTypesIds[
                `${topProgram}${
                    !hasSessionInPastYear ? '_INITIAL_' : '_FOLLOWUP_'
                }${selectedLang === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
            ]
        );
    }, [
        appointmentTypesInfo,
        hasSessionInPastYear,
        serviceTypesIds,
        selectedLang,
    ]);

    const createNewClientObject = async () => {
        const timeZoneValues = getClientTimeZoneObject(
            selectedCountry,
            selectedTimezone.value
        );
        const clientData = {
            allowWaitListText: false,
            cellPhone: phones[0]?.number,
            emailAddress: email,
            firstName,
            fullName: `${firstName} ${lastName}`,
            lastName,
            locale: selectedLang,
            status: 'ACTIVE',
            timeZone: {
                timeZoneCode: timeZoneValues.timeZoneCode,
                timeZoneDesc: timeZoneValues.timeZoneDesc,
                timeZoneId: timeZoneValues.timeZoneId,
                visible: true,
            },
        };

        try {
            const data = await TimeTap.createNewClientObject(
                sessionToken,
                clientData
            );

            setClientObject(data);
        } catch (error) {
            setAPIError(error);
        }

        return;
    };

    const getClientObject = useCallback(() => {
        TimeTap.getClientObject(sessionToken, email)
            .then(data => {
                // Member is already in TimeTap database
                if (data?.length > 1) {
                    setClientObject(data[0]);
                }
                // Member needs to be added in the TimeTap database
                else if (data?.length === 1 && data[0]?.clientId === -1) {
                    createNewClientObject();
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken, email]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCommentFieldObject = useCallback(() => {
        const schedulerPreferenceFieldDefnId = IS_PROD ? '1463330' : '1521652';

        TimeTap.getCommentFieldObject(
            sessionToken,
            schedulerPreferenceFieldDefnId
        )
            .then(data => {
                setCommentFieldObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken]);

    const getLocationObject = useCallback(() => {
        TimeTap.getLocationObject(sessionToken, TIMETAP_CCM_LOCATION_ID)
            .then(data => {
                setLocationObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken, TIMETAP_CCM_LOCATION_ID]);

    const getReasonObject = useCallback(() => {
        TimeTap.getReasonObject(sessionToken, serviceId)
            .then(data => {
                setReasonObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken, serviceId]);

    const getCoaches = useCallback(() => {
        TimeTap.getStaff(sessionToken, serviceId, TIMETAP_CCM_LOCATION_ID).then(
            data => {
                setCoachesData(data);

                const coachNames = [];

                if (coaches[0].value !== '') {
                    coachNames.push({
                        label: coaches[0].value,
                        value: coaches[0].key,
                    });
                }

                coachNames.push({
                    label: t('sessionInfo.anyCoach'),
                    value: ANY_COACH,
                });

                data?.forEach(coach => {
                    coachNames.push({
                        label: coach.internalDisplayName,
                        value: coach.professionalId.toString(),
                    });
                });

                setListOfCoachNames(coachNames);
            }
        );

        return;
    }, [sessionToken, serviceId, TIMETAP_CCM_LOCATION_ID, t]);

    const getAllCoachesAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        const monthsAhead = 2;

        for (let i = 0; i <= monthsAhead; i++) {
            let startDate;
            const endDate = format(
                endOfMonth(add(new Date(), {months: i})),
                DATE_FORMAT
            );

            if (i === 0) {
                startDate = format(new Date(), DATE_FORMAT);
            } else {
                startDate = format(
                    startOfMonth(add(new Date(), {months: i})),
                    DATE_FORMAT
                );
            }

            requests.push(
                TimeTap.getAllCoachesAvailableDates(
                    sessionToken,
                    serviceId,
                    startDate,
                    endDate,
                    TIMETAP_CCM_LOCATION_ID
                )
            );
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data
                    ?.flat()
                    ?.filter(availableDate => availableDate.timeSlots.length);
                const parsedDates = availableDatesResponse.map(({date}) => {
                    return parse(date, DATE_FORMAT, new Date());
                });
                const formattedDates = parsedDates.map(date => {
                    return format(date, 'MM/dd/yyyy');
                });

                // Getting time slots for current day to see if there are any available that
                // are 2 hours in advance in order to mark this day in the calendar as available
                if (format(dateInstance, 'MM/dd/yyyy') === formattedDates[0]) {
                    const startTime = addHours(new Date(), 2).getTime();
                    const filteredTimes = availableDatesResponse[0]?.timeSlots.filter(
                        timeSlot => timeSlot.staffStartDateTimeUTC >= startTime
                    );

                    if (isEmpty(filteredTimes)) {
                        // Remove current day because there's no available times that are 2 hours in advance
                        formattedDates.shift();
                    } else {
                        setFilteredTimeSlots(filteredTimes);
                    }
                }

                setAvailableDatesData(availableDatesResponse);
                setAvailableDates(formattedDates);

                if (parsedDates.length) {
                    setInitialMonth(format(parsedDates[0], 'MM/dd/yyyy'));
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [serviceId, sessionToken, TIMETAP_CCM_LOCATION_ID]);

    // Trigerring a re-render for the time selection so that it shows the placeholder text for the new day selected
    const renderTimeSelection = () => {
        setTimeKey(uuidv4());
        setAvailableTimes([]);
    };

    // const onLanguageChange = ({target}) => {
    //     const locale = target.value;
    //     // Resetting these values to reset the calendar and time selection until new dates are fetched for the new serviceId

    //     setInitialMonth(null);
    //     setAvailableDates([]);

    //     renderTimeSelection();

    //     setServiceId(
    //         serviceTypesIds[
    //             `${rankedProgram}${
    //                 !hasPastSession ? '_INITIAL_' : '_FOLLOWUP_'
    //             }${locale === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
    //         ]
    //     );

    //     changeLanguage(locale);
    // };

    // const onPastSessionChange = hasHadPastSession => {
    //     // Resetting these values to reset the calendar and time selection until new dates are fetched for the new serviceId
    //     setInitialMonth(null);
    //     setAvailableDates([]);
    //     renderTimeSelection();

    //     setServiceId(
    //         serviceTypesIds[
    //             `${rankedProgram}${
    //                 !hasHadPastSession ? '_INITIAL_' : '_FOLLOWUP_'
    //             }${selectedLang === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
    //         ]
    //     );
    //     setHasPastSession(hasHadPastSession === t('myStrength.yes'));
    // };

    const getCoachAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        const monthsAhead = 2;

        for (let i = 0; i <= monthsAhead; i++) {
            const year = format(add(dateInstance, {months: i}), 'yyyy');
            const month = format(add(dateInstance, {months: i}), 'MM');

            if (selectedCoachId !== ANY_COACH) {
                requests.push(
                    TimeTap.getCoachAvailableDates(
                        sessionToken,
                        year,
                        month,
                        selectedCoachId ?? coaches[0].key,
                        serviceId,
                        SESSION_DURATION,
                        TIMETAP_CCM_LOCATION_ID
                    )
                );
            }
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data?.flat();
                const formattedDates = availableDatesResponse.map(
                    ({month, day, year}) => {
                        const date = month
                            .toString()
                            .concat(
                                '/',
                                day.toString().concat('/', year.toString())
                            );
                        const parsedDate = parse(
                            date,
                            'M/d/yyyy',
                            dateInstance
                        );

                        return format(parsedDate, 'MM/dd/yyyy');
                    }
                );

                // Getting time slots for current day to see if there are any available that
                // are 2 hours in advance in order to mark this day in the calendar as available
                if (format(new Date(), 'MM/dd/yyyy') === formattedDates[0]) {
                    TimeTap.getCoachAvailableTimes(
                        sessionToken,
                        dateInstance.getFullYear(),
                        format(dateInstance, 'MM'),
                        format(dateInstance, 'dd'),
                        selectedCoachId ?? coaches[0].key,
                        serviceId,
                        SESSION_DURATION,
                        TIMETAP_CCM_LOCATION_ID
                    ).then(timesData => {
                        if (!isEmpty(timesData)) {
                            const startTime = addHours(new Date(), 2).getTime();
                            const filteredTimes = timesData.filter(
                                // eslint-disable-next-line max-nested-callbacks
                                timeSlot =>
                                    timeSlot.staffStartDateTimeUTC >= startTime
                            );

                            if (isEmpty(filteredTimes)) {
                                // Remove current day because there's no available times that are 2 hours in advance
                                formattedDates.shift();
                            } else {
                                setFilteredTimeSlots(filteredTimes);
                            }
                        }
                    });
                }
                setAvailableDates(formattedDates);

                if (formattedDates.length) {
                    setInitialMonth(formattedDates[0]);
                }

                if (formattedDates?.length === 0) {
                    // add a slight delay to allow the state change to execute properly
                    setInitialMonth(null);
                    setAvailableDates([]);
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken, selectedCoachId, serviceId, TIMETAP_CCM_LOCATION_ID]);

    const getLastSessionData = useCallback(() => {
        if (!isPreviousCoachSelected) {
            return;
        } else {
            TimeTap.getLastSessionData(
                sessionToken,
                clientObject.clientId
            ).then(data => {
                const professionalId = data?.staff?.professionalId?.toString();

                if (professionalId) {
                    setHasSessionInPastYear(true);
                    setPreviousSessionCoach(professionalId);
                    coaches[0].key = data?.staff?.professionalId?.toString();
                    coaches[0].value = data?.staff?.fullName;
                    setSelectedCoachId(professionalId);
                    getCoachAvailableDates();
                } else {
                    setHasSessionInPastYear(false);
                }

                if (
                    listOfCoachNames.find(
                        ({value}) =>
                            value?.toString() === professionalId?.toString()
                    )
                ) {
                    setSelectedCoachId(professionalId);
                }
                // There is an edge case where the coach from the last session isn't in
                // the list of coach names because i.e. they don't have availability in the
                // next 3 months, so we pre-select the "Any Coach" option in the dropdown
                else {
                    setSelectedAnyCoach(true);
                    setSelectedCoachId(ANY_COACH);
                    getAllCoachesAvailableDates();
                }
            });
        }

        return;
    }, [
        sessionToken,
        clientObject,
        listOfCoachNames,
        isPreviousCoachSelected,
        getAllCoachesAvailableDates,
        getCoachAvailableDates,
    ]);

    // const onCoachSelected = coachIdValue => {
    //     // Trigerring a re-render for the calendar so that it shows the
    //     // placeholder text instead of the selected day for the previous coach
    //     setInitialMonth(null);
    //     setAvailableDates([]);

    //     renderTimeSelection();

    //     if (coachIdValue === ANY_COACH) {
    //         setSelectedAnyCoach(true);
    //         getAllCoachesAvailableDates();
    //     } else {
    //         setSelectedAnyCoach(false);
    //         setSelectedCoachId(coachIdValue);
    //     }
    // };

    const onDaySelected = date => {
        const dateInstance = new Date(date);

        if (availableTimes) {
            renderTimeSelection();
        }

        if (selectedAnyCoach) {
            let timeSlots;

            // For the current day, we already filtered the time slots in getAllCoachesAvailableDates()
            if (dateInstance.toDateString() === new Date().toDateString()) {
                const dateObjectTemp = {
                    timeSlots: filteredTimeSlots,
                };
                const tempDateData = availableDatesData
                    ? TimeTap.getOtherCoachesAvailableDatesMinusTarget(
                          [dateObjectTemp],
                          previousSessionCoach
                      )
                    : [dateObjectTemp];

                timeSlots = availableDatesData
                    ? tempDateData[0]?.tsTemp
                    : filteredTimeSlots;
            } else {
                const formattedDate = format(dateInstance, DATE_FORMAT);
                const tempDateData = previousSessionCoach
                    ? TimeTap.getOtherCoachesAvailableDatesMinusTarget(
                          availableDatesData,
                          previousSessionCoach
                      )
                    : availableDatesData;
                const dateData = tempDateData.filter(
                    availableDate => availableDate.date === formattedDate
                );

                // const dateData = availableDatesData.filter(
                //     availableDate => availableDate.date === formattedDate
                // );

                timeSlots = dateData[0]?.tsTemp
                    ? dateData[0]?.tsTemp
                    : dateData[0].timeSlots;
            }

            setTimeSlotsData(timeSlots);
            setAvailableTimes(
                timeSlots.map(timeSlot => {
                    const convertedDate = new Date(
                        timeSlot.staffStartDateTimeUTC
                    ).toISOString();

                    return {
                        label: localizedTime(
                            convertedDate,
                            selectedTimezone.value,
                            selectedLang
                        ),
                        value: timeSlot.staffStartDateTimeUTC.toString(),
                    };
                })
            );
        } else {
            const splitDate = date.split('/');
            const month = splitDate[0];
            const day = splitDate[1];
            const year = splitDate[2];

            TimeTap.getCoachAvailableTimes(
                sessionToken,
                year,
                month,
                day,
                selectedCoachId,
                serviceId,
                SESSION_DURATION,
                TIMETAP_CCM_LOCATION_ID
            )
                .then(data => {
                    let timeSlots;

                    // For the current day, we already filtered the time slots in getCoachAvailableDates()
                    if (
                        dateInstance.toDateString() ===
                        new Date().toDateString()
                    ) {
                        timeSlots = filteredTimeSlots;
                    } else {
                        timeSlots = data;
                    }

                    setTimeSlotsData(timeSlots);
                    setAvailableTimes(
                        timeSlots?.map(timeSlot => {
                            const convertedDate = new Date(
                                timeSlot.staffStartDateTimeUTC
                            ).toISOString();

                            return {
                                label: localizedTime(
                                    convertedDate,
                                    selectedTimezone.value,
                                    selectedLang
                                ),
                                value: timeSlot.staffStartDateTimeUTC.toString(),
                            };
                        })
                    );
                })
                .catch(error => {
                    setAPIError(error);
                });
        }
    };

    const onTimeSelected = time => {
        const timeSlotData = timeSlotsData.filter(
            timeSlot => timeSlot.staffStartDateTimeUTC === Number(time.value)
        );

        setSelectedTimeSlotData(timeSlotData[0]);

        if (
            selectedAnyCoach ||
            showFirstVisitMsg ||
            coaches[0].key === '' ||
            coaches[0].value === ''
        ) {
            const timeSlotInfo = timeSlotData[0].units;

            // More than 1 coach has the time slot available
            if (timeSlotInfo.length > 1) {
                const requests = [];

                timeSlotInfo.forEach(timeSlot => {
                    if (
                        timeSlot?.professionalId?.toString() !==
                        previousSessionCoach?.toString()
                    ) {
                        requests.push(
                            TimeTap.getCoachAppointmentCount(
                                sessionToken,
                                timeSlot.professionalId
                            )
                        );
                    }
                });
                Promise.all(requests).then(data => {
                    // Finding which coach has the least amount of appointments
                    const coachInfo = data.reduce((prev, curr) => {
                        if (prev.count === curr.count) {
                            return 'sameCount';
                        }

                        return prev.count < curr.count ? prev : curr;
                    });

                    if (coachInfo === 'sameCount') {
                        // Picking a coach randomly
                        setCoachId(
                            timeSlotInfo[
                                Math.floor(Math.random() * timeSlotInfo.length)
                            ].professionalId
                        );
                    } else {
                        setCoachId(coachInfo.coachId);
                    }
                });
            } else {
                setCoachId(timeSlotInfo[0].professionalId);
            }
        } else {
            setCoachId(selectedCoachId ?? coaches[0].key);
        }
    };

    const scheduleAppointment = () => {
        setSessionKey(uuidv4());

        if (isSharedCoaching) {
            if (sharedCoaching) {
                delete sharedCoaching.uuid;
            }

            SharedCoaching.submitSharedCoaching(
                {
                    ...sharedCoaching,
                    reason: 1,
                    message: notes,
                },
                uuid,
                token
            ).then(() => {
                navigate(
                    enableNewUi
                        ? `/v2/confirmation?enableNewUi=true&view=fullview&showSession=true&${
                              isMobile ? 'mobile=true' : ''
                          }`
                        : '/confirmation'
                );
            });
        } else {
            const coachData = coachesData.filter(
                coach =>
                    coach.professionalId?.toString() === coachId?.toString() ||
                    coach.professionalId?.toString() ===
                        selectedCoachId?.toString()
            );
            const timeZoneValues = getClientTimeZoneObject(
                selectedCountry,
                selectedTimezone.value
            );

            clientObject.timeZone.timeZoneCode = timeZoneValues?.timeZoneCode;
            clientObject.timeZone.timeZoneDesc = timeZoneValues?.timeZoneDesc;
            clientObject.timeZone.timeZoneId = timeZoneValues?.timeZoneId;
            commentFieldObject.value = notes;
            uuidFieldObject.value = internalUserId;

            const appointmentData = {
                client: clientObject,
                clientEndDate: selectedTimeSlotData.clientEndDate,
                clientEndTime: selectedTimeSlotData.clientEndTime,
                clientReminderHours: 24,
                clientStartDate: selectedTimeSlotData.clientStartDate,
                clientStartTime: selectedTimeSlotData.clientStartTime,
                endDate: selectedTimeSlotData.staffEndDate,
                endTime: selectedTimeSlotData.staffEndTime,
                fields: [commentFieldObject, uuidFieldObject],
                location: locationObject,
                reason: reasonObject,
                remindStaffSmsHrs: 0,
                remindClientSmsHrs: 24,
                sendConfirmationToClient: true,
                sendConfirmationToStaff: true,
                staff: coachData[0],
                staffReminderHours: 24,
                startDate: selectedTimeSlotData.staffStartDate,
                startTime: selectedTimeSlotData.staffStartTime,
                status: 'OPEN',
            };

            TimeTap.createAppointmentNonProxyCcmOnly(
                sessionToken,
                appointmentData
            )
                .then(data => {
                    dispatch({
                        type: 'APPOINTMENT_DETAILS',
                        payload: {
                            startDate: new Date(data?.startDateTimeUTC),
                            endDate: new Date(data?.endDateTimeUTC),
                            timezone: selectedTimezone.value,
                            type: data?.reason?.reasonDesc,
                            coach:
                                data?.staff?.internalDisplayName ??
                                data?.staff?.fullName,
                            method: data?.location?.locationName,
                        },
                    });
                    dispatch({
                        type: 'VIEW',
                        payload: {
                            enableNewUi: true,
                            view: 'fullview',
                            dontShowLoader: true,
                            calendarId: data?.calendarId,
                        },
                    });
                    MixpanelUtils.track({
                        event: 'coaching.scheduling.success',
                    });
                    navigate(
                        enableNewUi
                            ? `/v2/confirmation?enableNewUi=true&view=fullview&showSession=true&calendarId=${
                                  data?.calendarId
                              }&${isMobile ? 'mobile=true' : ''}`
                            : '/confirmation'
                    );
                })
                .catch(err => {
                    setAPIError(err);
                    MixpanelUtils.track({
                        event: 'coaching.scheduling.failure',
                        properties: {
                            'Error Code': err.status,
                        },
                    });
                });

            // Check if user has schedule a coaching session before.
            SharedCoaching.getLaunchpadStatus(token)
                .then(({items}) => {
                    const coachStatus = items?.find(
                        item => item.name === 'COACHING'
                    );

                    // User has not schedule a session
                    if (!coachStatus.completed) {
                        const markItem = {
                            item: {name: 'COACHING', completed: true},
                        };

                        SharedCoaching.markLaunchpadStatus(token, markItem);
                    }
                })
                .catch(err => {
                    // silent
                });
        }
    };

    const getSchedulerFieldsObjects = useCallback(() => {
        TimeTap.getSchedulerFieldsObjects(sessionToken)
            .then(data => {
                setUuidFieldObject(data[5]);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [sessionToken]);

    const languageChanged = ({target: {value}}) => {
        const locale = value;
        // Resetting these values to reset the calendar and time selection until new dates are fetched for the new serviceId

        setInitialMonth(null);
        setAvailableDates([]);

        renderTimeSelection();

        setServiceId(
            serviceTypesIds[
                `${rankedProgram}${
                    !hasPastSession ? '_INITIAL_' : '_FOLLOWUP_'
                }${locale === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
            ]
        );

        changeLanguage(locale);
    };

    const differentCoachChange = target => {
        const selected =
            target?.target?.id === 'Any Coach' ? ANY_COACH : target?.target?.id;

        // Trigerring a re-render for the calendar so that it shows the
        // placeholder text instead of the selected day for the previous coach
        setInitialMonth(null);
        setAvailableDates([]);

        renderTimeSelection();

        if (selected === ANY_COACH) {
            setIsPreviousCoachSelected(false);
            setSelectedAnyCoach(true);
            getAllCoachesAvailableDates();
            setSelectedCoachId(selected);
            setShowFirstVisitMsg(true);
        } else if (!isNil(coaches[0].key)) {
            setIsPreviousCoachSelected(true);
            setSelectedAnyCoach(false);
            setSelectedCoachId(selected);
            getCoachAvailableDates();
            setShowFirstVisitMsg(false);
        }
    };

    const pastSessionChanged = ({target: {value}}) => {
        if (value.toString() === CONFIRMATION[1].value.toString()) {
            setShowFirstVisitMsg(true);
            setShowPastVisitMsg(false);
        } else {
            setShowFirstVisitMsg(false);
            setShowPastVisitMsg(true);
        }
        // setInitialMonth(null);
        // setAvailableDates([]);
        // renderTimeSelection();

        // setServiceId(
        //     serviceTypesIds[
        //         `${rankedProgram}${
        //             !value ? '_INITIAL_' : '_FOLLOWUP_'
        //         }${selectedLang === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
        //     ]
        // );
        // setHasPastSession(value === t('myStrength.yes'));
    };

    useEffect(() => {
        if (isSharedCoaching) {
            window.location.href = `/${location?.search?.replace(
                'enableNewUi=true',
                ''
            )}`;
        }
    }, [isSharedCoaching, location]);

    useEffect(() => {
        SharedCoaching.coachingInfo(token, uuid)
            .then(data => {
                setIsSharedCoaching(data.sharedCoaching);

                if (data.sharedCoaching) {
                    window.location.href = `/${location?.search?.replace(
                        'enableNewUi=true',
                        ''
                    )}`;
                }
            })
            .catch(err => {
                dispatch(UserInfoAPI.incorrectToken);
            });

        setSessionToken(token);
    }, [token, uuid, enableNewUi, window, location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sessionToken) {
            setServiceTypeId();
        }
    }, [sessionToken, setServiceTypeId]);

    useEffect(() => {
        if (sessionToken && serviceId) {
            if (clientObject && !isEmpty(listOfCoachNames)) {
                getLastSessionData();
            }
        }
    }, [
        sessionToken,
        serviceId,
        clientObject,
        listOfCoachNames,
        getLastSessionData,
    ]);

    useEffect(() => {
        if (sessionToken && serviceId) {
            getClientObject();
            getLocationObject();
            getReasonObject();
            getCommentFieldObject();
            getCoaches();
            getSchedulerFieldsObjects();
        }
    }, [
        sessionToken,
        serviceId,
        getClientObject,
        getLocationObject,
        getReasonObject,
        getCommentFieldObject,
        getCoaches,
        getSchedulerFieldsObjects,
    ]);

    // useEffect(() => {
    //     if (sessionToken && serviceId) {
    //         if (clientObject && !isEmpty(listOfCoachNames)) {
    //             getLastSessionData();
    //         }
    //     }
    // }, [
    //     sessionToken,
    //     serviceId,
    //     clientObject,
    //     listOfCoachNames,
    //     getLastSessionData,
    // ]);

    useEffect(() => {
        if (sessionToken && serviceId) {
            if (selectedCoachId && !selectedAnyCoach) {
                setTimeout(() => {
                    // add a slight delay to allow the state change to execute properly
                    getCoachAvailableDates();
                }, 2000);
            }
        }
    }, [
        sessionToken,
        serviceId,
        selectedCoachId,
        selectedAnyCoach,
        getCoachAvailableDates,
    ]);

    useEffect(() => {
        setEnableNewUi(
            CommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.enableNewUi,
                oneApp
            )
        );
        setEnableNewUi(useNewUi);
    }, [featureFlags, oneApp, useNewUi]);

    useEffect(() => {
        if (token && uuid) {
            UserInfoAPI.schedulingData(token, uuid)
                .then(data => {
                    dispatch({
                        type: 'USER_DATA',
                        payload: data,
                    });
                })
                .catch(({data}) => {
                    dispatch({
                        type: 'ERROR',
                        payload: JSON.stringify(data),
                    });
                    dispatch(UserInfoAPI.incorrectToken);
                });
            UserInfoAPI.getInternalUserId(token, uuid)
                .then(data => {
                    setInternalUserId(data?.uuid);
                })
                .catch(err => {
                    setAPIError(err);
                });
        }
    }, [token, uuid, dispatch]);

    useEffect(() => {
        if (APIError) {
            MixpanelUtils.track({
                event: 'coaching.scheduling.error',
                properties: {
                    'Error Code': APIError.status,
                },
            });
        }
    }, [APIError]);

    const defaultLanguage = selectedLang;

    useEffect(() => {
        MixpanelUtils.track({
            event: 'coaching.scheduling.viewed',
        });
    }, []);

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    return (
        <div>
            <AcuityError isOpen={APIError} toggleModal={setAPIError} />
            <div className={css2.root}>
                <Form
                    className={css2.innerFrame}
                    onSubmit={() => {
                        scheduleAppointment();
                    }}
                >
                    <div className={css2.header}>
                        <div className={css2.logoWrapper}>
                            {!isMobile && (
                                <div className={css2.newHeader}>
                                    {t('newTitle')}
                                </div>
                            )}
                            {isNull(hasSessionInPastYear) && <Loader />}
                            {!isNull(hasSessionInPastYear) &&
                                !hasSessionInPastYear && (
                                    <div className={css2.tipWrapperClear}>
                                        <span className={css2.description}>
                                            {t(
                                                'sessionInfo.firstVisitWithCoach.description'
                                            )}
                                        </span>
                                    </div>
                                )}
                            <div>{t('required')}</div>
                        </div>
                        <CheckableGroup
                            id="languageTag"
                            name="languageTag"
                            label={
                                <Label aria-hidden={isMobile}>
                                    {t('sessionInfo.newLanguage')}
                                </Label>
                            }
                            error={
                                <FormElementError>
                                    {t('form.mealTypeError')}
                                </FormElementError>
                            }
                            key={defaultLanguage}
                            required
                            allowMultiSelect={false}
                            onChange={e => languageChanged(e)}
                        >
                            {Object.keys(languages).map(language => {
                                return (
                                    <Chip
                                        key={languages[language].key}
                                        id={languages[language].key}
                                        value={String(
                                            languages[language].value
                                        )}
                                        label={
                                            languages[
                                                language
                                            ].key.toString() === 'english'
                                                ? t(`sessionInfo.english`)
                                                : t(`sessionInfo.spanish`)
                                        }
                                        defaultChecked={
                                            languages[language].value ===
                                            defaultLanguage
                                        }
                                        role="radio"
                                    />
                                );
                            })}
                        </CheckableGroup>
                        {showPastSession &&
                            !isNull(hasSessionInPastYear) &&
                            hasSessionInPastYear && (
                                <CheckableGroup
                                    id="experienceTag"
                                    name="experienceTag"
                                    label={
                                        <Label aria-hidden={isMobile}>
                                            {t('sessionInfo.newPastSession')}
                                        </Label>
                                    }
                                    error={
                                        <FormElementError>
                                            {t('form.mealTypeError')}
                                        </FormElementError>
                                    }
                                    key={CONFIRMATION?.toString()}
                                    required
                                    allowMultiSelect={false}
                                    onChange={e => pastSessionChanged(e)}
                                >
                                    {CONFIRMATION.map((confirmation, index) => {
                                        return (
                                            <Chip
                                                key={confirmation.key}
                                                id={confirmation.key}
                                                value={String(
                                                    confirmation.value
                                                )}
                                                label={
                                                    confirmation.key === 'No'
                                                        ? t(`myStrength.no`)
                                                        : t(`myStrength.yes`)
                                                }
                                                defaultChecked={
                                                    hasSessionInPastYear?.toString() ===
                                                    confirmation.value?.toString()
                                                }
                                                aria-label={`${t(
                                                    'sessionInfo.pastSession',
                                                    {
                                                        name: confirmation.key,
                                                        number: index + 1,
                                                        total: Object.keys(
                                                            CONFIRMATION
                                                        ).length,
                                                        title: t(
                                                            'sessionInfo.pastSession'
                                                        ),
                                                    }
                                                )}`}
                                                role="radio"
                                            />
                                        );
                                    })}
                                </CheckableGroup>
                            )}
                        {(coaches[0].key !== '' || coaches[0].value !== '') &&
                            showPastVisitMsg && (
                                <CheckableGroup
                                    id="differentCoach"
                                    name="differentCoach"
                                    label={
                                        <Label aria-hidden={isMobile}>
                                            {t('sessionInfo.preferred')}
                                        </Label>
                                    }
                                    error={
                                        <FormElementError>
                                            {t('form.mealTypeError')}
                                        </FormElementError>
                                    }
                                    key={ANY_COACH}
                                    required
                                    allowMultiSelect={false}
                                    onChange={e => differentCoachChange(e)}
                                    role="radiogroup"
                                >
                                    {coaches.map(coach => {
                                        return (
                                            <Chip
                                                key={coach.key}
                                                id={coach.key}
                                                value={coach.key}
                                                label={`${coach.value}`}
                                                defaultChecked={
                                                    coach.key === coaches[0].key
                                                }
                                                role="radio"
                                            />
                                        );
                                    })}
                                </CheckableGroup>
                            )}
                        {hasSessionInPastYear && showFirstVisitMsg && (
                            <div className={css2.tipWrapper}>
                                <span className={css2.iconInfo}>
                                    <IconInfoDefault />
                                </span>
                                <span className={css2.description}>
                                    <span className={css2.firstTimeDesc}>
                                        {t(
                                            'sessionInfo.firstVisitWithCoach.title'
                                        )}
                                    </span>
                                    <br />
                                    {t(
                                        'sessionInfo.firstVisitWithCoach.description'
                                    )}
                                </span>
                            </div>
                        )}
                        <UserInfo newUi={useNewUi} isMobile={isMobile} />
                        <div className={css2.timeContainer}>
                            <DatePicker
                                placeHolder={t('Selectadate')}
                                key={initialMonth}
                                id="date"
                                name="date"
                                classNameRoot={cnb({
                                    [css2.dateTimePicker]: isMobile,
                                })}
                                classNameInput={css2.datePickerInput}
                                calendar={{
                                    disablePast: true,
                                    disableBefore: initialMonth,
                                    initialMonth,
                                    available: availableDates,
                                }}
                                disabled={!initialMonth}
                                label={
                                    <Label aria-hidden={isMobile}>
                                        {t('sessionInfo.date')}
                                    </Label>
                                }
                                onChange={date => onDaySelected(date)}
                                required
                                aria-label={t('sessionInfo.calendar')}
                            />
                        </div>
                    </div>
                    <div>
                        <Select
                            id="timeZone"
                            name="timeZone"
                            label={
                                <Label aria-hidden={isMobile}>
                                    {t('sessionInfo.timezone')}
                                </Label>
                            }
                            placeholder={t('sessionInfo.timezonePlaceholder2')}
                            defaultValue={null}
                            items={timeZones[selectedCountry].map(
                                ({value, label}) => ({
                                    value,
                                    label: t(`timeZone.${label}`),
                                })
                            )}
                            onChange={tz => timezoneChange(tz)}
                            required
                        />
                        <Select
                            key={timeKey}
                            id="time"
                            name="time"
                            disabled={isEmpty(availableTimes)}
                            label={
                                <Label aria-hidden={isMobile}>
                                    {t('sessionInfo.time')}
                                </Label>
                            }
                            items={availableTimes}
                            onChange={time => onTimeSelected(time)}
                            placeholder={t('sessionInfo.timePlaceholder')}
                            required
                        />
                    </div>
                    <TextArea
                        id="note"
                        name="note"
                        maxLength={255}
                        label={<Label>{t('sessionInfo.note')}</Label>}
                        onChange={e => setNotes(e.target.value)}
                    />
                    {sessionKey !== '0' && (
                        <div
                            key={sessionKey}
                            ref={contentRef}
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            className={css2.xScreenReader}
                        >
                            {t('sessionInfo.loading')}
                        </div>
                    )}
                    <div className={css2.actions}>
                        <Button
                            key={sessionKey}
                            id="scheduleSession"
                            name="scheduleSession"
                            type="submit"
                            variant="primary"
                            className={css2.confirmButton}
                            disabled={
                                !isSharedCoaching
                                    ? isEmpty(selectedTimeSlotData)
                                    : false
                            }
                            loading={sessionKey !== '0'}
                        >
                            {t('sessionInfo.submit')}{' '}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SchedulingFormV2;
